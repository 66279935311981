<template>
  <div class="tc_control_questionnaire_result_look">
    <!-- 分数统计 -->
    <div class="statistics">
      <!-- <el-row v-if="typeNum.length<=0" type="flex" justify="center">-没有选择题目-</el-row> -->

      <el-row>
        <el-col :span="4" v-for="(value,key) in allData.type" :key="key" style="margin-top: 5px;">
          <span>{{key | filterType}}-</span>已选择
          <span>{{allData.num[key]}} 道-</span>
          <span>
            <el-input v-model="allData.type[key]" size="small"></el-input>
          </span>
          <span>-总分</span>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="allData.questionnaire"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        max-height="700"
        class="tableBox"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column prop="title" label="题目名称" align="left" header-align="center"></el-table-column>
        <el-table-column prop="type" label="题目类型" width="200" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.type | filterType}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="share" label="共享" width="130" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.share | filterShare}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="130">
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="plain" @click="goReport(scope.row.id)">查看</el-button> -->
            <!-- <el-divider direction="vertical"></el-divider> -->
            <!-- <el-button size="mini" type="plain" @click="showAddtestPaper(scope.row)">编辑</el-button> -->
            <el-button size="mini" type="plain" @click="del(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加按钮 -->
    <div class="btn">
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="seveScore">保存分数</el-button>
        <el-button type="primary" @click="back">返回</el-button>
      </el-row>
    </div>
  </div>
</template>
<script>
import { setQsubject, delCertain, upCores } from "@/api/questionnaire.js";
import { log } from "util";
export default {
  data() {
    return {
      allData: {}
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    // 移除题目
    del(row) {
      console.log(row);
      let data = {
        qids: row.id,
        id: this.$route.query.id
      };
      delCertain(data).then(res => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "成功移除该题目！",
            type: "success"
          });
          this.loadList();
        }
      });
    },
    // 列表渲染
    loadList() {
      //    获取参数
      let title = this.$route.query.title;
      let id = this.$route.query.id;
      let data = {
        id
      };
      setQsubject(data).then(res => {
        //console.log(res);
        this.allData = res.data;
      });
    },
    // 保存分数
    seveScore() {
      let data = {
        id: this.$route.query.id,
        cores: this.allData.type
      };
      upCores(data).then(res => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success"
          });
        }
      });
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    }
  },
  filters: {
    filterType(val) {
      let arr = [
        "单选题",
        "多选题",
        "判断题",
        "填空题",
        "排序题",
        "计算题",
        "解答题",
        "问答题",
        "作文题"
      ];
      return arr[val - 1];
    }
  }
};
</script>
<style lang="less">
.tc_control_questionnaire_result_look {
  // 统计
  .statistics {
    // background: rgb(217, 242, 247);
    background-color: #FFDEC6;
    margin-top: 5px;
    font-size: 14px;
    color: rgb(4, 87, 102);
    padding: 10px;
    .el-input {
      width: 65px;
    }
    .el-input__inner {
      width: 65px;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 650px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      // color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      // background: rgb(217, 242, 247);
      color: #4B2000;
      background: #FFDEC6;
      border-right: none !important;
      border-left: none !important;
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
      border-right: none !important;
      border-left: none !important;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: #FFAB71;
      border: none !important;
      font-size: 14px;
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 按钮
  .btn {
    margin-top: 10px;
  }
}
</style>